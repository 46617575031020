#big-search-query-input {
    min-height: calc(100vh - 54.5px);
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 100px;
    text-align: center;

    &::placeholder {
        font-size: 70px;
        color: #bfbfbf;
    }

    &:focus {
        box-shadow: none;
    }
}
