#nav-bar {
    min-width: 880px;
    background-color: #161a47;

    .navbar-brand {
        font-size: 16px;
    }

    .navbar-content {
        display: flex!important;
        flex-basis: auto;
        flex-grow: 1;
        align-items: center;

        ul {
            flex: 1;
        }
    }

    .navbar-nav {
        flex-direction: row;
    }

    .navbar-auth {
        height: 100%;
        align-self: flex-end;
    }

    .nav-link {
        padding: 0.5rem;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.75);

        &:hover, &:focus, &.active {
            color: white;
        }
    }

    .navbar-auth {
        align-self: flex-end;
        font-size: 15px;

        .greeting {
            padding: 0.5rem;
            color: white;
        }

        .logout {
            display: inline-block;
            padding-right: 0;

            &:hover {
                color: white;
            }
        }
    }
}

#content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    min-height: calc(100vh - 54.5px);

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        margin-right: 1.5rem;
        padding: 10px;
        border-radius: 4px;
        background-color: #f3f3f3;
        border: 1px solid #bfbfbf;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #777777;

        &:hover {
            background-color: #666666;
        }
    }
}
