$dispute-background-color-light-gray: #f3f3f3;
$dispute-border-color-dark-gray: #777777;
$dispute-input-border-radius: 16px;
$cip-border-radius: 8px;

#customer-details-container {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #e8e8ed;
}

#customer-details {
    width: 1024px;

    label {
        margin-left: 4px;
    }
}

#search-group {
    display: flex;
    align-items: center;

    input {
        width: 294px;
        height: 40px;
        border: 1px solid $dispute-border-color-dark-gray;
        border-radius: $dispute-input-border-radius;

        &::placeholder {
            font-size: 14px;
        }

        &::-webkit-calendar-picker-indicator {
            display: list-item!important;
            margin-top: -7px;
        }
    }

    .search-icon {
        font-size: 24px;
        margin-left: 4px;
        padding: 0 4px;
        cursor: pointer;
        color: #666666;

        &:hover {
            color: black;
        }
    }
}

#customer-username-container {
    a {
        text-decoration: none;
    }
}

#customer-note-container {
    .collapse-btn {
        color: var(--bs-link-color);
        cursor: pointer;

        &:hover {
            color: var(--bs-link-hover-color);
        }
    }

    .control-group {
        display: flex;
        align-items: flex-end;
        height: 29px;
    }

    .control-btn {
        display: inline-block;
        margin-bottom: 1px;
        margin-left: 4px;
        font-size: 18px;
        cursor: pointer;

        &:first-child {
            margin-left: 8px;
        }

        &.greyed-out {
            pointer-events: none;
            color: grey;
            filter: grayscale(100%);
        }
    }

    textarea {
        height: 180px;
        resize: none;
        border-radius: $cip-border-radius;

        &:read-only, &:disabled {
            background-color: $dispute-background-color-light-gray;
        }

        &:read-only {
            cursor: pointer;
        }

        &:disabled {
            cursor: default;
            pointer-events: none;
        }
    }
}

#customer-dispute-history-table-wrapper {
    overflow-x: auto;

    table {
        min-width: 976px;
        max-width: 976px;

        th {
            font-weight: normal;
            text-align: center;
            background-color: #b9bac8;
        }

        td {
            vertical-align: middle;
            font-size: 14px;
            background-color: $dispute-background-color-light-gray;

            &:nth-child(1) {
                width: 82.5px;
            }

            &:nth-child(2) {
                width: 125px;
            }

            &:nth-child(3) {
                width: 199.5px;
            }

            &:nth-child(4) {
                width: 85px;
            }

            &:nth-child(5) {
                width: 85px;
            }

            &:nth-child(6) {
                width: 199.5px;
            }

            &:nth-child(7) {
                width: 199.5px;
            }

            .draft-icon {
                margin-left: 2px;
            }

            .credit-amount {
                border-bottom: 1px dashed $dispute-border-color-dark-gray;
                cursor: pointer;
            }
        }

        thead {
            th:first-child {
                border-top-left-radius: $cip-border-radius;
            }

            th:last-child {
                border-top-right-radius: $cip-border-radius;
            }
        }

        tbody > tr:last-child {
            td:first-child {
                border-bottom-left-radius: $cip-border-radius;
            }

            td:last-child {
                border-bottom-right-radius: $cip-border-radius;
            }
        }

        &.empty {
            min-width: 100%;
            max-width: 100%;
        }
    }

    &::-webkit-scrollbar-track {
        margin-right: 0;
    }
}
