body {
    font-family: 'Inter', sans-serif;

    &.being-dragged {
        cursor: grabbing;
    }
}

.external-link::after {
    content: "​";
    padding-right: 1.15rem;
    background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M17 13.5v6H5v-12h6m3-3h6v6m0-6-9 9" class="icon_svg-stroke" stroke="%23666" stroke-width="1.5" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path></svg>');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

.one-line-max {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.two-lines-max {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.three-lines-max {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.Toastify__toast-container {
    width: auto!important;
    min-width: 320px;
    max-width: 500px;
}
