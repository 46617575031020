$dispute-two-columns-threshold: 1500px;
$dispute-primary-accent-color: #45486c;
$dispute-secondary-accent-color: #5c5f7e;
$dispute-darker-accent-color: #2d3159;
$dispute-background-color-light-gray: #f3f3f3;
$dispute-border-color-light-gray: #bfbfbf;
$dispute-border-color-dark-gray: #777777;
$dispute-input-border-radius: 16px;

#dispute-panel {
    z-index: 0;
    min-width: 700px;
    flex-shrink: 1;

    input:not([type=checkbox]):not([type=radio]) {
        border: 1px solid $dispute-border-color-dark-gray;
        border-radius: $dispute-input-border-radius;

        &::placeholder {
            font-size: 14px;
        }
    }

    input[type="text"] {
        width: 300px;
        height: 40px;

        &::-webkit-calendar-picker-indicator {
            display: list-item!important;
            margin-top: -7px;
        }
    }

    input[type="number"] {
        width: 128px;
        height: 40px;
    }
}

#search-group {
    display: flex;
    align-items: center;

    input {
        width: 294px;
    }

    .search-icon {
        font-size: 24px;
        margin-left: 4px;
        padding: 0 4px;
        cursor: pointer;
        color: #666666;

        &:hover {
            color: black;
        }
    }
}

#listing-cards-container {
    overflow-x: hidden;
    white-space: nowrap;

    &.overflows {
        overflow-x: scroll;
        padding-bottom: 8px;
    }
}

#listing-cards-wrapper {
    display: inline-block;
    vertical-align: top;
    cursor: grab;
}

.listing-card {
    display: inline-block;
    vertical-align: top;
    width: 445px;
    height: 193px;
    margin-right: 1.5rem;
    padding: 16px;
    border: 1px solid $dispute-border-color-light-gray;
    border-radius: 16px;
    background-color: $dispute-background-color-light-gray;
    cursor: pointer;

    &:hover {
        border-color: $dispute-border-color-dark-gray;
    }

    &.selected {
        border-color: #3c72c3;
        background-color: #d8e3f3;
        cursor: default;

        label {
            color: #838487;
        }

        span {
            color: black;
        }
    }

    .card-row {
        margin-bottom: 16px;
    }

    .card-row:last-child {
        margin-bottom: 0;
    }

    .card-row:nth-child(2) {
        height: 56px;
    }

    p {
        display: inline-block;
        vertical-align: top;
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: normal;
    }

    label {
        display: block;
        margin-bottom: 2px;
        font-size: 12px;
        color: #c2c2c2;
        cursor: inherit;
    }

    span {
        font-size: 14px;
        color: #808080;

        &.one-line-max, &.two-lines-max {
            margin-top: 4px;
        }
    }

    .valpn {
        width: 32.5%;
    }

    .auction-item {
        width: 25%;
    }

    .customer {
        width: 42.5%;
    }

    .product {
        width: 100%;
        white-space: normal;
    }

    .bid-price {
        width: 20%;
    }

    .buyers-premium {
        width: 30%;
    }

    .lot-fee {
        width: 15%;
    }

    .tax {
        width: 15%;
    }

    .total {
        width: 20%;
        text-align: right;
        float: right;
    }
}

.being-dragged #listing-cards-wrapper, .being-dragged .listing-card {
    cursor: inherit;
}

#dispute-split-panel {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;

    @media screen and (min-width: $dispute-two-columns-threshold) {
        flex-direction: row;
        justify-content: flex-start;
    }
}

#amount-input-wrapper {
    position: relative;

    input {
        padding-left: 23px;
        font-size: 20px;

        &:disabled {
            background-color: $dispute-background-color-light-gray;
        }
    }

    &::before {
        content: "$";
        position: absolute;
        left: 10px;
        top: 52%;
        transform: translateY(-50%);
        font-size: 20px;
        pointer-events: none;
    }
}

#amount-input-group {
    display: flex;
    align-items: center;

    input[type="checkbox"]:checked {
        background-color: $dispute-primary-accent-color;
        border-color: $dispute-primary-accent-color;
    }

    input[type="checkbox"]:checked {
        background-color: $dispute-primary-accent-color;
        border-color: $dispute-primary-accent-color;
    }

    .form-check-label {
        margin-left: -3px;
    }
}

#partial-credit-shortcut-container {
    display: flex;
    justify-content: space-between;
    width: 128px;
    margin-top: -3px;
    padding: 0 4px;
    font-size: 13.5px;

    span {
        color: #257dfd;
        cursor: pointer;

        &:hover {
            color: var(--bs-link-hover-color);
        }
    }
}

#tag-container {
    width: 300px;

    .tag {
        display: inline-block;
        border-radius: 50rem;
        margin-right: 0.25rem;
        margin-bottom: 0.25rem;
        padding: 0.125rem 0.5rem;
        background-color: $dispute-background-color-light-gray;
        color: black;
        font-size: 0.8rem;
        cursor: pointer;

        &:hover, &.selected {
            color: white;
        }

        &:hover {
            background-color: $dispute-secondary-accent-color;
        }

        &.selected, &.selected:hover {
            background-color: $dispute-primary-accent-color;
        }

        &:last-child {
            margin-right: 0;
            margin-bottom: 1.5rem;
        }
    }
}

#submit-btn-group {
    button {
        background-color: $dispute-primary-accent-color;
        border-color: $dispute-primary-accent-color;

        &:hover {
            background-color: $dispute-darker-accent-color;
            border-color: $dispute-darker-accent-color;
        }
    }

    .dropdown-item {
        cursor: pointer;

        &:active {
            background-color: $dispute-secondary-accent-color;
        }
    }
}

#item-details-container {
    display: flex;
    justify-content: flex-start;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;

    @media screen and (min-width: $dispute-two-columns-threshold) {
        flex-grow: 1;
        margin-bottom: 0;
        margin-left: 1.5rem;
    }

    &.scrollable {
        margin-left: -22px;

        @media screen and (min-width: $dispute-two-columns-threshold) {
            margin-left: 0;
        }
    }

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}

#item-details-wrapper {
    display: flex;
    max-width: 860px;
}

$selected-image-container-size: 300px;
$thumbnail-margin: 16px;
$thumbnail-size: calc(($selected-image-container-size - $thumbnail-margin * 3) / 4);
$carousel-btn-size: 32px;
$carousel-btn-margin: 8px;

#selected-image-container {
    width: $selected-image-container-size;
    height: $selected-image-container-size;
    min-width: $selected-image-container-size;
    min-height: $selected-image-container-size;
    margin-bottom: $thumbnail-margin;
    text-align: center;
}

.scrollable #selected-image-container {
    margin-left: calc($carousel-btn-size + $carousel-btn-margin);
}

#thumbnail-wrapper {
    width: $selected-image-container-size;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .carousel-btn {
        display: none;
    }
}

.scrollable #thumbnail-wrapper {
    width: calc($selected-image-container-size + $carousel-btn-size * 2 + $carousel-btn-margin * 2);
    justify-content: space-between;

    .carousel-btn {
        display: block;
        width: $carousel-btn-size;
        height: $carousel-btn-size;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        filter: invert(1) grayscale(100);
        opacity: 0.5;
        transition: opacity .15s ease;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }

        &.disabled {
            opacity: 0.25;
            cursor: default;
            pointer-events: none;
        }

        &.carousel-prev-btn {
            margin-right: $carousel-btn-margin;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%23fff"><path d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg>');
        }

        &.carousel-next-btn {
            margin-left: $carousel-btn-margin;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%23fff"><path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>');
        }
    }
}

#thumbnail-container {
    width: $selected-image-container-size;
    display: flex;
    justify-content: center;
    overflow: hidden;

    .thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $thumbnail-size;
        height: $thumbnail-size;
        min-height: $thumbnail-size;
        min-width: $thumbnail-size;
        margin-right: $thumbnail-margin;
        border: 1.5px solid $dispute-border-color-light-gray;
        text-align: center;
        cursor: pointer;

        &:hover {
            border-color: $dispute-border-color-dark-gray;
        }

        &.selected {
            border-color: $dispute-border-color-dark-gray;
            cursor: default;
            pointer-events: none;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.scrollable #thumbnail-container {
    justify-content: space-between;
}

#item-details {
    max-height: calc($selected-image-container-size + $thumbnail-margin + $thumbnail-size);
    overflow-y: auto;
    margin-left: 16px;

    p {
        margin-bottom: 10px;
        line-height: normal;

        &:last-child {
            margin-bottom: 0;
        }
    }

    label {
        display: block;
        margin-bottom: 2px;
        font-size: 14px;
        font-weight: bold;
    }

    span {
        font-size: 15px;
        color: black;
    }

    .label-group {
        display: flex;
        height: 18px;
        align-items: center;
        margin-bottom: 2px;

        label {
            margin-bottom: 0;
        }

        .label-badge, .label-badge:hover, .label-badge:visited {
            display: block;
            margin-left: 2px;
            padding: 3px 5px;
            border-radius: 4px;
            font-size: 12px;
            font-family: sans-serif;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 12px;
            color: white;
            text-decoration: none;

            &.badge-green {
                background-color: forestgreen;
            }

            &.badge-red {
                background-color: indianred;
            }

            &.badge-yellow {
                background-color: darkorange;
            }
        }
    }
}

.scrollable #item-details {
    max-height: calc($selected-image-container-size + $thumbnail-margin);
    margin-left: calc(16px - $carousel-btn-size - $carousel-btn-margin);
}

$cip-border-radius: 8px;

#customer-info-panel {
    z-index: 1;
    min-width: 490px;
    max-width: 800px;
    background-color: #e8e8ed;
    flex-shrink: 4;

    label {
        margin-left: 4px;
    }
}

#customer-username-container {
    a {
        text-decoration: none;
    }
}

#customer-note-container {
    .collapse-btn {
        color: var(--bs-link-color);
        cursor: pointer;

        &:hover {
            color: var(--bs-link-hover-color);
        }
    }

    .control-group {
        display: flex;
        align-items: flex-end;
        height: 29px;
    }

    .control-btn {
        display: inline-block;
        margin-bottom: 1px;
        margin-left: 4px;
        font-size: 18px;
        cursor: pointer;

        &:first-child {
            margin-left: 8px;
        }

        &.greyed-out {
            pointer-events: none;
            color: grey;
            filter: grayscale(100%);
        }
    }

    textarea {
        height: 180px;
        resize: none;
        border-radius: $cip-border-radius;

        &:read-only, &:disabled {
            background-color: $dispute-background-color-light-gray;
        }

        &:read-only {
            cursor: pointer;
        }

        &:disabled {
            cursor: default;
            pointer-events: none;
        }
    }
}

#customer-dispute-history-table-wrapper {
    overflow-x: auto;

    table {
        min-width: 917.5px;
        max-width: 917.5px;

        th {
            font-weight: normal;
            text-align: center;
            background-color: #b9bac8;
        }

        td {
            vertical-align: middle;
            font-size: 14px;
            background-color: $dispute-background-color-light-gray;

            &:nth-child(1) {
                width: 82.5px;
            }

            &:nth-child(2) {
                width: 125px;
            }

            &:nth-child(3) {
                width: 180px;
            }

            &:nth-child(4) {
                width: 85px;
            }

            &:nth-child(5) {
                width: 85px;
            }

            &:nth-child(6) {
                width: 180px;
            }

            &:nth-child(7) {
                width: 180px;
            }

            .draft-icon {
                margin-left: 2px;
            }

            .credit-amount {
                border-bottom: 1px dashed $dispute-border-color-dark-gray;
                cursor: pointer;
            }
        }

        thead {
            th:first-child {
                border-top-left-radius: $cip-border-radius;
            }

            th:last-child {
                border-top-right-radius: $cip-border-radius;
            }
        }

        tbody > tr:last-child {
            td:first-child {
                border-bottom-left-radius: $cip-border-radius;
            }

            td:last-child {
                border-bottom-right-radius: $cip-border-radius;
            }
        }

        &.empty {
            min-width: 100%;
            max-width: 100%;
        }
    }

    &::-webkit-scrollbar-track {
        margin-right: 0;
    }
}
